@tailwind components;

@layer components {
  .fav-search-input-wrapper {
    @apply bg-base-white border  border-cement-100 w-[100%] h-[3.5rem] flex items-center justify-between pl-5 pr-2.5 py-3 relative rounded-lg;
  }

  .fav-search-input {
    @apply placeholder-black w-[100%]  focus-within:placeholder-transparent outline-none text-sm;
  }
}
