@tailwind components;

@layer components {
  /*  large */
  .property-listing-page {
    /* min height = viewheight minus header height */
    @apply min-h-[calc(100vh-52px)] flex flex-col justify-between;
  }

  .property-listing-header {
    @apply flex justify-center w-full h-16 md:h-24;
  }

  .back-button-wrapper {
    @apply float-left w-1/2;
  }

  .property-listing-actions {
    @apply flex justify-end space-x-2 md:space-x-4;
  }

  .property-listing-overview {
    @apply bg-cement-100 flex h-[18.5rem] justify-center mb-12 px-10;
  }

  .property-unit-specs {
    @apply flex justify-center pb-4 px-10;
  }

  .button-tooltip {
    @apply bg-[#d9d9d9] text-[#5c5a66];
  }
}

/* needed to override inline styles on Mapbox marker */
.listing-map > .mapboxgl-map > .mapboxgl-canvas-container > .mapboxgl-marker {
  @apply !pointer-events-none;
}

.contact-banner {
  bottom: 0px;
  background-color: #efefef;
  position: fixed;
}

.contact-banner-bottom {
  background-color: #efefef;
  position: relative;
}

.contact-banner-footer {
  bottom: 96px;
  background-color: #efefef;
  position: fixed;
}

.image-scroll::-webkit-scrollbar {
  display: none;
}

.data-disclaimer-mobile{
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #898989;
  font-weight: 400;
}

.data-disclaimer-desktop{
  font-size: 14px;
  line-height: 20px;
  color: #898989;
  font-weight: 400;
}

