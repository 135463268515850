@tailwind components;

@layer components {
  @media only screen and (max-width: 767px) {
    .sm-top {
      top: 0px !important;
    }
  }
  .table-cell {
    font-size: 1rem !important;
    font-family: TT\ Hoves,sans-serif !important;
  }
}
