@tailwind components;

@layer components {
  .sort-outer-container {
    @apply relative z-10 inline-block px-2 min-w-fit;
  }

  .sort-inner-container {
    @apply relative text-sm outline-none;
  }

  .filter-list {
    @apply absolute right-0 p-2 rounded-lg shadow-lg bg-white-100 border-cement-200 w-44;
  }

  .filter-option {
    @apply p-1 text-sm cursor-pointer hover:text-rust-100;
  }
}
