@tailwind components;

@layer components {
  .pagination-main-div {
    display: flex;
    border-top: 1px solid rgba(224, 224, 224, 1);
  }
  .pagination-list,
  .pagination-records-count,
  .pagination-select-page-size {
    display: inline-flex;
    align-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
  .pagination-list-children {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
  .selected-page {
    text-decoration: underline;
    font-weight: bold;
  }
  .pagination-select-page-size .dropdown-list {
    top: -125px !important;
  }
  .button-wrapper {
    @apply border border-black;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .pagination-main-div {
      background-color: #fff;
      z-index: 15;
    }
    .left-section {
      padding-left: 30px;
      padding-right: 0px;
    }
    .right-section {
      padding-left: 0px;
      padding-right: 30px;
    }
    .listings-dropdown {
      margin-left: 20px;
      width: 5rem;
    }
    .pagination-list {
    padding-left: 0px;
    padding-right: 0px;
  }
  }
}
