@tailwind components;

@layer components {
  /*  large */
  nav {
    height: 52px;
  }

  nav .brand {
    height: 1.75rem;
    margin-left: 0.25rem;
  }

  nav .brand-mobile {
    display: none;
  }

  nav .nav-right {
    margin-right: 1.5rem;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .smoke-test-banner {
    /* This section calls the slideInFromTop animation we defined above */
    animation: 1s ease-in 0s 1 slideInFromTop;

    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    background: #252429;
    border-radius: 6px;
    max-width: 190px;
    color: white;
  }

  /*  medium */
  @media only screen and (max-width: 1024px) {
    nav {
      height: 52px;
    }

    nav .brand {
      height: 1.75rem;
      margin-left: 0.25rem;
    }

    nav .nav-right {
      margin-right: 0.7rem;
    }
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    nav {
      height: 52px;
    }

    nav .brand {
      display: none;
    }

    nav .brand-mobile {
      height: 1.6rem;
      margin-left: 1.5rem;
      display: block;
    }

    nav .nav-right {
      margin-right: 1.5rem;
    }

    nav .nav-items {
      display: none;
    }
  }
}
