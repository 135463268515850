@tailwind components;

@layer components {
  .predictive-search-input {
    @apply absolute bg-transparent cursor-pointer placeholder-black outline-none py-0.5 w-full z-10;
  }

  .predictive-search-input-outer-wrapper {
    @apply border border-black bg-white-100 cursor-pointer flex h-12 items-center justify-between pl-5 pr-2.5 rounded-lg;
  }

  .predictive-search-results {
    @apply h-full max-h-[12.25rem] overflow-y-auto p-2 text-white-100 w-full;
  }

  .predictive-search-results-wrapper {
    @apply absolute bg-slate-400 border border-slate-100 h-fit overflow-hidden rounded-lg w-full z-[12];
  }
}