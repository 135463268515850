@tailwind components;

@layer components {
  /* Authing Other Than Below */
  .custom-max-w-specs {
    max-width: 10rem;
  }
  /* @media (min-width: 768px) and (max-width: 799px) {
    .custom-max-w-specs {
      max-width: 4rem;
    }
  }
  @media (min-width: 800px) and (max-width: 866px) {
    .custom-max-w-specs {
      max-width: 5rem;
    }
  }
  @media (min-width: 867px) and (max-width: 920px) {
    .custom-max-w-specs {
      max-width: 6rem;
    }
  } */
}
