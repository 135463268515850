@tailwind components;

@layer components {
  .carousel-navigation {
    @apply absolute z-10 flex items-center justify-center w-12 h-12 rounded-full cursor-pointer hover:opacity-50 bg-white-100;
  }

  .carousel-wrapper {
    @apply relative flex items-center justify-center w-full h-full;
  }

  .carousel-wrapper .swiper-custom-pagination {
    @apply absolute flex justify-center left-0 my-0 mx-auto right-0 top-[-3.1875rem] text-xl text-white-100 w-fit;
    bottom: unset;
  }

  .carousel-wrapper .swiper-pagination-current {
    @apply mr-1;
  }

  .carousel-wrapper .swiper-pagination-total {
    @apply ml-1;
  }

  .carousel-wrapper .react-transform-component {
    @apply w-full h-full;
  }

  .swiper {
    @apply max-h-[calc(100vh-8rem)] h-[29rem] w-[70.625rem];
  }

  .swiper .swiper-slide {
    @apply flex items-center justify-center text-center rounded-lg bg-white-100;
  }

  .swiper-slide img {
    @apply block object-contain w-full h-full;
  }

  /*
  VideoJS Styling Overrides
  Using default styling from 'video.js/dist/video-js.css'
  Below styling overrides. Must use important indicator to override default styles
  */

  .video-js {
    @apply !text-base !text-white-100;
  }

  .video-js .vjs-control-bar,
  .video-js .vjs-menu-button .vjs-menu-content {
    @apply !bg-black !opacity-70;
  }

  .video-js .vjs-big-play-button {
    @apply !bg-transparent !text-[6rem] !leading-[1.7em] !border-none;
  }

  .video-js:hover .vjs-big-play-button {
    @apply !opacity-50;
  }

  .video-js .vjs-control-bar {
    @apply !bottom-[-0.0625rem];
    visibility: visible !important;
    transition: visibility 0.1s, opacity 0.1s !important;
  }

  .video-js .vjs-control-bar.vjs-fade-out {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: visibility 1.5s, opacity 1.5s !important;
    transition-delay: 2s !important;
  }

  .video-js .vjs-load-progress div {
    @apply !bg-solar-500;
  }

  /* Un-hide control(s) if required */
  .video-js .vjs-volume-panel,
  .video-js .vjs-picture-in-picture-control {
    @apply !hidden;
  }

  @media only screen and (min-width: 360px) and (max-width: 767px) {
    .carousel-navigation {
      @apply hover:opacity-100 bg-white-100;
    }
    .swiper-button-prev {
      top: 10px;
      left: 110px;
    }
    .swiper-button-next {
      top: 10px;
      right: 110px;
    }
    .image-container {
      top: 110px;
    }
    .mobile-button {
      top: 15px;
      right: 10px;
    }
    .custom-wrapper .swiper .swiper-slide {
      @apply bg-transparent;
    }
  }
}
