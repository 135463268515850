@tailwind components;

@layer components {
  .react-multi-email > input {
    background-color: #eee;
  }

  .react-multi-email [data-tag] {
    background-color: rgb(215 215 215);
    padding: 0.55em 1em;
  }

  .multiple-email {
    width: inherit;
  }
}
