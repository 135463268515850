@tailwind components;

@layer components {
  .dropdown-list {
    @apply absolute z-20 w-full p-2 overflow-y-auto border rounded-lg shadow bg-slate-400 border-slate-100 max-h-56 text-white-100;
  }

  .dropdown-list-option {
    @apply p-2 rounded cursor-pointer;
  }

  .button-wrapper {
    @apply relative flex items-center justify-between h-full px-4 border rounded-lg cursor-pointer;
  }

  .ul-display-block {
    display: block !important;
  }
}
